import {getToken} from "../Hooks/useToken";
async function login(email, password) {
  const url = process.env.REACT_APP_API + 'login_check';
  const resp = await fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      "username": email,
      "password": password
    })
  })

  if (resp.status === 200) {
    return resp.json()
  }
  return null
}

async function refreshToken() {
  const url = process.env.REACT_APP_API + 'refresh_token';
  const resp = await fetch(url, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + getToken()
    }
  })

  if (resp.status === 200) {
    return resp.json()
  }
  return null
}

export {login, refreshToken};
